<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    style="width: 500px; margin: 0 auto;"
    label-position="top"
  >
    <div class="tab-box">
      <div
        class="tab-item"
        :class="{active: activeName === 'password'}"
        @click="activeName = 'password'"
      >使用原密码</div>
      <div
        class="tab-item"
        :class="{active: activeName === 'verify'}"
        @click="activeName = 'verify'"
      >使用手机验证码</div>
    </div>
    <template v-if="activeName === 'password'">
      <el-form-item
        label="愿密码"
        prop="oldpassword"
      >
        <el-input
          v-model="formData.oldpassword"
          type="password"
          placeholder="请输入愿密码"
        ></el-input>
      </el-form-item>
    </template>
    <template v-if="activeName === 'verify'">
      <el-form-item
        label="手机号"
        prop="phone"
      >
        <el-input
          v-model="formData.phone"
          placeholder="输入手机号"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="验证码"
        prop="verify"
      >
        <div class="verify-box">
          <el-input
            v-model="formData.verify"
            placeholder="输入验证码"
            @keyup.enter.native="onRegist"
          >
          </el-input>
          <el-button
            type="primary"
            :disabled="verifyDisabled"
            @click="onGetVerify"
          >{{ verifyText }}</el-button>
        </div>
      </el-form-item>
    </template>

    <el-form-item
      label="新密码"
      prop="newpassword"
    >
      <el-input
        v-model="formData.newpassword"
        type="password"
        placeholder="请输入愿密码"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="确认密码"
      prop="newpasswordConf"
    >
      <el-input
        v-model="formData.newpasswordConf"
        type="password"
        placeholder="请输入愿密码"
      ></el-input>
    </el-form-item>
    <div>
      <el-button
        :loading="loading"
        type="primary"
        block
        @click="onConfirm"
      >确定</el-button>
    </div>
  </el-form>
</template>

<script>
import { updateUserPasswordByPassword, userPassword, getSms } from '@/api/admin'

export default {
  data() {
    const passwordValid = (rule, value, callback) => {
      if (
        this.formData.newpassword &&
        this.formData.newpasswordConf &&
        this.formData.newpassword !== this.formData.newpasswordConf
      ) {
        callback(new Error('俩次输入密码不相同'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      activeName: 'password',
      verifyText: '获取验证码',
      verifyDisabled: false,
      verifyTimer: null,
      formData: {
        phone: '',
        verify: '',
        oldpassword: '',
        newpassword: '',
        newpasswordConf: '',
      },
      formRules: {
        phone: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: this.$validator.isPhone, trigger: 'blur' },
        ],
        verify: [{ required: true, message: '必填项', trigger: 'blur' }],
        oldpassword: [{ required: true, message: '请输入愿密码', trigger: 'blur' }],
        newpassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { validator: passwordValid, trigger: 'blur' },
        ],
        newpasswordConf: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: passwordValid, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    onConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true

          if (this.activeName === 'password') {
            const params = {
              oldpassword: this.formData.oldpassword,
              newpassword: this.formData.newpassword,
            }
            updateUserPasswordByPassword(params)
              .then(res => {
                this.$message.success('成功修改密码')
                this.$store.dispatch('user/LOGOUT', 'login')
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            const params = {
              phone: this.formData.phone,
              verify: this.formData.verify,
              password: this.formData.newpassword,
              password_confirmation: this.formData.newpasswordConf,
            }
            userPassword(params)
              .then(res => {
                this.$message.success('成功修改密码')
                this.$store.dispatch('user/LOGOUT', 'login')
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    onGetVerify() {
      this.$refs.form.validateField('phone', valid => {
        if (!valid) {
          this.verifyDisabled = true
          getSms(this.formData.phone, 'change_password')
            .then(res => {
              let count = 60
              this.verifyText = `重新获取 ${count}s`
              this.verifyTimer = setInterval(() => {
                if (count < 1) {
                  this.verifyText = '获取验证码'
                  this.verifyDisabled = false
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                } else {
                  count--
                  this.verifyText = `重新获取 ${count}s`
                }
              }, 1000)

              this.$once('hook:beforeDestroy', () => {
                if (this.verifyTimer) {
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                }
              })
            })
            .catch(() => {
              this.verifyDisabled = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.verify-box {
  display: flex;

  .el-button {
    width: 160px;
  }
}

.tab-box {
  display: flex;
  height: 34px;
  margin: 20px 0 40px;
  line-height: 34px;

  .tab-item {
    flex: 1;
    min-width: 0;
    text-align: center;
    border: 1px solid #888;

    &:hover,
    &.active {
      color: #fff;
      cursor: pointer;
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }
}
</style>
